<template>
  <div class="i-main-content-wrapper tool--none" :class="{'fullscreen-item': isFullScreen }">
    <div class="tree-nav" v-show="isNavShow" v-if="hasMenu?monitorLeft:hasMenu">
      <tree-nav ref="aside" :show-arrow="true" :upgrade='upgrade' @click-device="clickDevice" @modify="modifyVehicle"
        @command="commandDevice" @click-batch="clickBatch" @enter-alarm="activeAlarmList" @modify-device="modifyDevice"
        @modify-user="modifyUser" @sendmsg="sendMessage" show-count>
      </tree-nav>
    </div>
    <div class="subBlock">
      <div class="map"
        :class="{'is-panoopen': isShowRealScene, 'is-animate': isAnimating, 'is-FixedToolbar': isFixedToolBar}"
        ref="container"></div>
      <div class="v-detail" :class="{'isHide':!isNavShow}" v-if="hasMenu">
        <drag-wrapper v-if="table.show" @end-drag="({y}) => table.height -= y" @start-drag="startDrag"></drag-wrapper>
        <arrow-button @click="table.show = !table.show" class="toggleTable" :position="table.show?'bottom':'top'">
        </arrow-button>

        <i-icon v-if="false" @click="table.show = !table.show" class="toggleTable"
          :name="`${table.show ? 'icon-shouqi-y' : 'icon-zhankai-y'}`" :class="{'is-expand': table.show}"></i-icon>
        <div class="tipGroup">
          <mapTips />
          <deviceNum :upgrade='upgrade' @enter-alarm="activeAlarmList" ref="deviceNum" />
        </div>
        <div v-if="table.show">
          <template v-if="table.viewTable===1">
            <el-table border stripe key="table1" size="mini" :data="tableList" :height="table.height"
              :default-sort="{prop:tableSort.name, order: tableSort.orderBy}" @row-dblclick="rowDbClick"
              @sort-change="changeSort" class="tracktable" style="width: 100%">
              <el-table-column prop="PeopleName" sortable :label="$t('vehicleDetail.peopleName')" width="100"
                v-if="viewColumns.includes('PeopleName')">
                <template slot-scope="{row}">{{row.PeopleName||'--'}}</template>
              </el-table-column>
              <el-table-column prop="VehicleName" sortable :label="$t('onlineMonitor.deviceName')" width="120"
                v-if="viewColumns.includes('VehicleName')" :show-overflow-tooltip="!table.isWrap">
                <template slot-scope="{row}">
                  <span v-if="row.VehicleList[0].TypeID===1">{{row.PersonName}}</span>
                  <span v-else-if="row.VehicleList[0].TypeID===2">{{row.ArtName}}</span>
                  <span v-else>{{row.VehicleName}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="VIN" sortable :label="$t('vehicleDetail.vehicleId')" width="140"
                v-if="viewColumns.includes('VIN')">
                <template slot-scope="{row}">{{row.VIN||'--'}}</template>
              </el-table-column>
              <el-table-column prop="SIM2" sortable :label="$t('deviceManage.deviceID')" width="140"
                v-if="viewColumns.includes('SIM2')">
                <template slot-scope="{row}">{{row.SIM2||'--'}}</template>
              </el-table-column>
              <el-table-column prop="TrackerTypeName" sortable :label="$t('deviceManage.TrackerTypeName')" width="70"
                v-if="viewColumns.includes('TrackerTypeName')">
                <template slot-scope="{row}">{{row.TrackerTypeName ? $t('dict')[row.TrackerTypeName] : '--'}}</template>
              </el-table-column>
              <el-table-column prop="Speed" sortable :label="$t('onlineMonitor.speed')+'(km/h)'" width="90"
                v-if="viewColumns.includes('Speed')">
                <template slot-scope="{row}">{{row.Speed}}</template>
              </el-table-column>
              <el-table-column prop="Mileage" sortable :label="$t('onlineMonitor.totalMile')+'(km)'" width="90"
                v-if="viewColumns.includes('Miles')">
                <template slot-scope="{row}">{{row.Mileage}}</template>
              </el-table-column>
              <el-table-column :key="80" prop="isOnline" sortable :label="$t('deviceManage.online')" width="55"
                v-if="viewColumns.includes('isOnline')">
                <template slot-scope="{row}">
                  <p class="square" :class="{isOnline: !!row.isOnline}"></p>
                </template>
              </el-table-column>
              <el-table-column :key="90" prop="Status" sortable :label="$t('deviceManage.acc')" width="55"
                v-if="viewColumns.includes('Acc')">
                <template slot-scope="{row}">
                  <template v-if="row.TypeID===0">
                    <template v-if="row.isOnline">
                      <p v-if="row.Status" class="imgcolumn">
                        <template v-if="!!(row.GPSFlag%2)">
                          <span v-if="!!row.Speed" class="status" alt="启动,行驶" :title="$t('deviceManage.keyOnRoad')">
                            <i class="s1"></i>
                          </span>
                          <span v-else class="status" alt="启动,怠速" :title="$t('deviceManage.keyDaisu')">
                            <i class="s2"></i>
                          </span>
                        </template>
                        <span v-else class="status" alt="启动,参考定位" :title="$t('deviceManage.keyRef')">
                          <i class="s3"></i>
                        </span>
                      </p>
                      <p v-else class="imgcolumn">
                        <span v-if="!!(row.GPSFlag%2)" class="status" alt="熄火" :title="$t('deviceManage.keyNoSpeed')">
                          <i class="s4"></i>
                        </span>
                        <span v-else class="status" alt="熄火,参考定位" :title="$t('deviceManage.keyNoSpeedRef')">
                          <i class="s5"></i>
                        </span>
                      </p>
                    </template>
                    <template v-else>
                      <p class="imgcolumn">
                        <span class="status" alt="离线" :title="$t('deviceManage.keyOffRef')">
                          <i class="s6"></i>
                        </span>
                      </p>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="row.isOnline">
                      <template v-if="!!(row.GPSFlag%2)">
                        <p class="imgcolumn">
                          <span v-if="!!row.Speed" class="status" alt="运动" :title="$t('deviceManage.keyMov')">
                            <i class="s1"></i>
                          </span>
                          <span v-else class="status" alt="静止" :title="$t('deviceManage.keyStop')">
                            <i class="s2"></i>
                          </span>
                        </p>
                      </template>
                      <template v-else>
                        <p class="imgcolumn">
                          <span class="status" alt="参考定位" :title="$t('deviceManage.keyNoGps')">
                            <i class="s5"></i>
                          </span>
                        </p>
                      </template>
                    </template>
                    <template v-else>
                      <p class="imgcolumn">
                        <span class="status" alt="离线" :title="$t('deviceManage.keyOffRef')">
                          <i class="s6"></i>
                        </span>
                      </p>
                    </template>
                  </template>
                </template>

              </el-table-column>
              <el-table-column :key="100" prop="isAlarm" sortable :label="$t('deviceManage.alarm')" width="55"
                v-if="viewColumns.includes('isAlarm')">
                <template slot-scope="{row}">
                  <p v-if="!!row.isAlarm" class="imgcolumn"><img src="../../assets/icon/报警.gif" alt="报警"
                      :title="$t('deviceManage.keyAlarm')" /></p>
                  <p v-else class="imgcolumn"><img src="../../assets/icon/正常.gif" alt="正常"
                      :title="$t('deviceManage.keyNormarl')" /></p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('onlineMonitor.statsDes')" sortable prop="StatusDes"
                :show-overflow-tooltip="!table.isWrap" v-if="viewColumns.includes('Status')">
                <template slot-scope="{row}">
                  <!-- color:#FF5722; -->
                  <span style="line-height:16px;" class="status">{{row.StatusDes}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="GPSTime" sortable :label="$t('onlineMonitor.gpsTime')" width="140"
                v-if="viewColumns.includes('GPSTime')">
                <p slot-scope="{row}">{{miment(row.GPSTime).format()}}</p>
              </el-table-column>
              <el-table-column prop="RcvTime" sortable :label="$t('onlineMonitor.rcvTime')" width="140"
                v-if="viewColumns.includes('RcvTime')">
                <p slot-scope="{row}">{{miment(row.RcvTime).format()}}</p>
              </el-table-column>
              <el-table-column :label="$t('onlineMonitor.place')" sortable prop="Address"
                :show-overflow-tooltip="!table.isWrap" width="380" v-if="viewColumns.includes('Address')">
                <template slot-scope="{row}">
                  <div>
                    <span @click.stop='toggleAddress(row,false)' class="pointer" v-if="row.showAddress">
                      {{row.Address}}</span>
                    <span @click.stop='toggleAddress(row,true)' class="pointer" v-else>{{row.Lon}},{{row.Lat}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="{row}">
                  <span class="i-link" @click.stop="removeOneDevice(row)">{{$t('onlineMonitor.remove')}}</span>
                </template>
              </el-table-column>
            </el-table>

            <el-pagination key="pager1" class="v-pager" small @size-change="handleSizeChange"
              @current-change="handleCurrentChange" :current-page.sync="table.pageIndex" :page-size="table.pageSize"
              :page-sizes="[10, 20, 50, 100 ,250]" layout="total, jumper, prev, pager,sizes, next" :total="list_length">
            </el-pagination>
          </template>
          <template v-if="table.viewTable===2">
            <el-table border stripe v-loading="!pageAlarmList.length && alarmTableLoading" key="table2" size="mini"
              :data="pageAlarmList" :height="table.height" @row-dblclick="rowDbClickAlarm" class="tracktable"
              style="width: 100%">
              <el-table-column prop="EventTime" :label="$t('onlineMonitor.gpsTime')" width="140">
                <p slot-scope="{row}">{{miment(row.EventTime).format()}}</p>
              </el-table-column>
              <el-table-column prop="VehicleNum" :label="$t('onlineMonitor.deviceName')" width="180"
                :show-overflow-tooltip="!table.isWrap"></el-table-column>

              <el-table-column prop="AlarmType" :label="$t('onlineMonitor.alarmType')" width="200">
                <p slot-scope="{row}" style="color:#FF5722;">{{row.AlarmType}}</p>
              </el-table-column>

              <el-table-column prop="Speed" :label="$t('onlineMonitor.speed')" width="70">
                <template slot-scope="{row}">{{row.Speed+" km/h"}}</template>
              </el-table-column>

              <el-table-column prop="Direct" :label="$t('onlineMonitor.directoin')" width="85">
                <template slot-scope="{row}">{{getDirectionDescription(row.Direct, $i18n.locale)}}</template>
              </el-table-column>

              <el-table-column :label="$t('onlineMonitor.place')" :show-overflow-tooltip="!table.isWrap">
                <template slot-scope="{row}">
                  <span-address :lon="row.Lon" :lat="row.Lat" :show-default="isShowAddressDefault"></span-address>
                </template>
              </el-table-column>

              <el-table-column :label="$t('onlineMonitor.enterprise')" :show-overflow-tooltip="!table.isWrap">
                <template slot-scope="{row}">{{row.HoldName}}</template>
              </el-table-column>

              <el-table-column width="100">
                <template slot-scope="{row}">
                  <span class="i-link" @click.stop="rowCancelAlarm(row)">{{$t('other.cancelAlarm')}}</span>
                </template>
              </el-table-column>

            </el-table>
            <el-pagination key="pager2" class="v-pager" small :current-page.sync="alarmPageIndex"
              :page-size="alarmPageSize" layout="total, jumper, prev, pager, next" :total="alarmList.length">
            </el-pagination>
          </template>

          <div class="table-check">
            <el-button type="text" class="flat tool-btn primary--text imr-4" @click="removeAllData" v-ripple><i
                class="el-icon-delete" /> {{$t('onlineMonitor.emptyList')}}</el-button>
            <el-checkbox v-model="isShowAddressDefault">{{$t('onlineMonitor.showAddress')}}</el-checkbox>
            <el-checkbox v-model="table.isWrap">{{$t('onlineMonitor.lineFeed')}}</el-checkbox>
            <img class="table-exchange" src="../../assets/icon/exchange.gif"
              @click="table.viewTable = 3 - table.viewTable" />
          </div>

        </div>
      </div>
      <div class="tool-btns" :class="{'isShowRealScene': isShowRealScene, 'is-FixedToolbar': isFixedToolBar}">
        <div class="kycsTip" v-if="platform">Please Note:As of February 1st 2021 Please login to the <a
            href="https://www.kycslocate.com" target="_blank">https://www.kycslocate.com</a></div>
        <!-- <span class="imr-1" v-if="isShowRealScene"><el-checkbox v-model="isShowRealScene">实景地图</el-checkbox></span> -->
        <div class="loading-container">
          <div class="loading-container__inner" v-loading="isLoadingMapData"></div>
        </div>
        <el-button type="primary" class="flat tool-btn primary--text first" v-show="!openedSearchBox">
          <change-map></change-map>
        </el-button>
        <!-- <el-button type="primary" class="flat tool-btn primary--text" @click="isFixedToolBar = !isFixedToolBar" v-ripple><i-icon name="icon-pin" v-if="isFixedToolBar"></i-icon><i-icon name="icon-pin-off" v-else></i-icon></el-button> -->
        <!-- <el-button type="primary" class="flat tool-btn primary--text" @click="sendMessage()" v-if="$i18n.locale==='zh' && importantInfo && loginInfo.allFuns.includes(20130)" v-ripple><i class="el-icon-message"></i><span v-show="!openedSearchBox">{{$t('onlineMonitor.sendSMS')}}</span></el-button> -->
        <el-button type="primary" class="flat tool-btn primary--text" @click="sendMessage()"
          v-if="$i18n.locale==='zh' && currentHoldId===1" v-ripple><i class="el-icon-message"></i><span
            v-show="!openedSearchBox">{{$t('onlineMonitor.sendSMS')}}</span></el-button>

        <search-xingqudian v-model="openedSearchBox" label="1" key="1" @select="selectPoi"
          v-if="tConfig.poi && loginInfo.isUser && importantInfo" @show="isShowPoi=!isShowPoi" @delete="removePoi"
          @save="savePoi"></search-xingqudian>
        <!-- <span><el-checkbox v-model="isShowAlarmRegion">风险区域</el-checkbox></span> -->
        <search-place v-model="openedSearchBox" label="2" key="2" @select="selectRegion"
          v-if="tConfig.region && loginInfo.isUser && importantInfo && loginInfo.allFuns.includes(3035)"
          @show="isShowAlarmRegion=!isShowAlarmRegion" @delete="removeRegion"></search-place>
        <!-- <span><el-checkbox v-model="isShoweryadian">二押点</el-checkbox></span> -->

        <el-button type="primary" class="flat tool-btn primary--text" @click="startFindRect"
          v-if="tConfig.rect && loginInfo.isUser && importantInfo" v-ripple>{{$t('onlineMonitor.regionSearch')}}
        </el-button>
        <el-button type="primary" class="flat tool-btn primary--text" @click="removeAllData" v-ripple>
          {{$t('onlineMonitor.emptyMap')}}</el-button>
        <el-button type="primary" class="flat tool-btn primary--text" @click="openBatchCommand"
          v-if="loginInfo.isUser && importantInfo && loginInfo.allFuns.includes(20121)" v-ripple>
          {{$t('onlineMonitor.batchCommand')}}</el-button>
        <el-badge v-if="false" :value="alarmCount" class="badge-item" :max="99" :hidden="alarmCount===0">
          <el-button round :disabled="alarmCount===0">
            <i-icon class="alarm" name="icon-bell-ring" v-if="alarmCount > 0"></i-icon>
            {{alarmCount>0?$t('onlineMonitor.alarm'):$t('onlineMonitor.noAlarm')}}
          </el-button>
        </el-badge>
        <el-button type="primary" class="flat tool-btn" @click="$root.$emit('dialog-setting')"
          v-tooltip="$t('onlineMonitor.setViewItem')" v-ripple>
          <i-icon name="icon-settings" />
        </el-button>
        <el-button type="primary" class="flat tool-btn" @click="saveMapPosition" v-tooltip="$t('onlineMonitor.save')"
          v-ripple>
          <i-icon name="icon-save" />
        </el-button>
        <!-- <div type="primary" v-if="hasMenu" class="mapRuler" @click="toggle_ruler__start" v-tooltip="$t('onlineMonitor.ruler')" v-ripple><i-icon name="icon-juli" /></div> -->
        <el-button type="primary" class="flat tool-btn" @click="$refs.aside.refresh()" v-if="importantInfo" v-ripple
          v-tooltip="$t('onlineMonitor.refreshUserTree')"><i class="el-icon-refresh"></i></el-button>
        <el-button type="primary" class="flat tool-btn" @click="isFullScreen = !isFullScreen" v-if="importantInfo"
          v-ripple v-tooltip="$t('onlineMonitor.fullscreen')">
          <i-icon :name="isFullScreen?'icon-fullscreen-exit':'icon-fullscreen'" style="font-size:17px;"></i-icon>
        </el-button>
      </div>
      <!-- <div class="v-video" :class="{'is-video': isShowVideo}" :style="bottomHeight">
       <div v-if="isShowVideo" style="z-index:999">
         <iframe :src="videoUrls" class="video" frameborder="0"></iframe>
       </div>
       <span class="closeVideo" @click="showVideo" v-if="isShowVideo"><i class="el-icon-close"></i></span>
    </div> -->
      <!-- <drag-dialog :visible.sync="isShowVideo" :videoparams="dvrInfo" v-show="isShowVideo"></drag-dialog> -->
      <div class="v-pano" :class="{'is-panoopen': isShowRealScene}" ref="">
        <!-- <div v-if="isShowRealScene">
       <iframe class="box" :src="url" frameborder="0"></iframe> 
      </div> -->
        <div v-show="isShowRealScene" style="z-index: 9" class="v-wrap">
          <iframe id="mainIframe" ref="mainIframe" class="box" src="https://www.365qczx.com/Jiejing/StreetBD2.html"
            frameborder="0"></iframe>
        </div>
        <!-- <div class="nofollow" style="z-index: 5" v-if="flashNotAllow">
        <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" data-i width="100%" height="100%" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,29,0">
          <param name="wmode" value="transparent">
          <embed src="media.swf" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" width="100%" height="100%" />
        </object>
      </div> -->
        <span class="closeBtn" @click="isShowRealScene=false" v-if="isShowRealScene"><i
            class="el-icon-close"></i></span>
      </div>
      <div class="map--tip" :class="{'isNavShow':isNavShow}">
        <transition-group name="slidegroup">
          <div class="countdown" v-if="list.length > 0" key="1">
            <i class="el-icon-time" style="margin-right:4px;"></i>
            {{$t('onlineMonitor.refreshTip', { count:countdown })}}
            <el-button @click="refreshNow" type="text" :disabled="isLoadingMapData || refreshBtnBusy">
              {{$t('common.refresh')}}</el-button>
            <el-button @click="end_timer" v-if="isShowPause && countdown_timer"
              :disabled="isLoadingMapData || refreshBtnBusy" type="text">{{$t('onlineMonitor.refreshAction')}}
            </el-button>
            <el-button @click="start_timer" v-if="isShowPause && !countdown_timer"
              :disabled="isLoadingMapData || refreshBtnBusy" type="text">{{$t('onlineMonitor.refreshResume')}}
            </el-button>
          </div>
          <p class="tip__item" v-if="mapTool.ruler" key="2">
            <i class="el-icon-warning" style="margin-right:4px;"></i>{{$t('onlineMonitor.distanceTip')}}
          </p>
          <p class="tip__item" v-if="isOnFindByRect" key="5">
            <i class="el-icon-warning" style="margin-right:4px;"></i>{{$t('onlineMonitor.regionSearchTip')}} <span
              style="color:#f44336; cursor:pointer;" @click="endFindRect">{{$t('onlineMonitor.exit')}}</span>
          </p>
          <p class="tip__item" v-if="isOnFindByRectLoading" key="6"><i class="el-icon-loading"
              style="margin-right:4px;"></i>正在查找...</p>
          <p class="tip__item ipt-1" v-if="mapTool.vToolGroup" key="3">
            <el-checkbox v-model="mapTool.bilichi">比例尺</el-checkbox><br />
            <el-checkbox v-model="mapTool.gongjutiao">工具条</el-checkbox><br />
            <el-checkbox v-model="mapTool.yingyan">鹰眼</el-checkbox><br />
            <span class="item__split"></span>
            <span class="item__action" @click="mapTool.vToolGroup=false">关闭</span>
          </p>
          <p class="tip__item" v-if="isLoadingEryadian" key="4"><i class="el-icon-loading"
              style="margin-right:4px;"></i>加载二押点...</p>
          <p class="tip__item" v-if="isVisibleEryadianTip" key="5" style="color: #f44336;">
            <i class="el-icon-warning" style="margin-right:4px;"></i>请放大地图以显示二押点
          </p>
        </transition-group>
      </div>
      <map-place class="online-map-place" @select="mapPlaceSelect" v-if="$i18n.locale==='zh'"></map-place>
      <!-- <div class="fixed-tool">
      <div class="layers-ui" v-if="layerSelectVisible">
        <div class="sidebar_heading">
          <i class="el-icon-close" @click="layerSelectVisible=false"></i>
          <h4>{{$t('onlineMonitor.layers')}}</h4>
        </div>
        <div class="base-layers">
          <ul>
            <li v-for="(k, index) in mapLayerOptions" :key="index" :class="{'active': currentMapLayer===index, 'hide': !k.type.includes('google')}" @click="currentMapLayer=index">
              <img :src="k.img" />
              <label >{{$t(`onlineMonitor.${k.name}`)}}</label>
            </li>
          </ul>
        </div>
      </div>
      <ul>
        <li @click="layerSelectVisible=!layerSelectVisible" :class="{'active':layerSelectVisible}">
          <i-icon name="icon-layers"></i-icon>
        </li>
      </ul>
    </div> -->

    </div>
    <el-dialog :custom-class="iframe.class" :visible.sync="isShowDialog" :width="iframe.width" :title="iframe.title"
      @closed="closedIframe" append-to-body v-if="isShowDialogReal">
      <el-row type="flex" class="targetSIMInput" align="middle" v-if="targetSIMInputVisible">
        <span>{{$t('onlineMonitor.simcard')}}：</span>
        <el-input v-model="targetSIM" @blur="sendMessageBySIM(targetSIM)"
          :placeholder="$t('onlineMonitor.pleaseInputSimcard')"></el-input>
      </el-row>

      <div v-loading="iframeLoading" element-loading-spinner="el-icon-loading" element-loading-background="#FFFFFF"
        style="position:relative;">
        <iframe frameborder="0" class="mine" :height="iframe.height" width="100%" scrolling="yes" :src="iframe.src"
          @load="iframeLoading=false"></iframe>
        <div class="targetSIMInput-empty"
          v-if="targetSIMInputVisible && (iframe.src == '#' || iframe.src == 'about:blank')">
          <i-icon name="icon-message-bulleted"></i-icon>
          <span>{{$t('onlineMonitor.inputSimcardSeeHistory')}}</span>
        </div>
      </div>
    </el-dialog>
    <!-- 指令发送 弹窗 -->
    <el-dialog :visible.sync="isShowDialogBatch" :title="$t('onlineMonitor.batchCommand')"
      custom-class="v-dialog v-dialog--md v-dialog--mdpadding" append-to-body v-if="isShowDialogBatchReal"
      @closed="isShowDialogBatchReal = false">
      <batch-command :current-type="currentCommandTracker" :current-command-default="currentCommandDefault"
        :checked-object-keys-default="checkedObjectKeysDefault" :current-command-single="currentCommandSingle"
        :device-info="deviceInfo"></batch-command>
    </el-dialog>

    <dialog-cellandlbs ref="dialogCellAndLbs"></dialog-cellandlbs>

    <ul class='contextmenu' v-show="panelContext.visible"
      :style="{left:panelContext.left+'px',top:panelContext.top+'px'}">
      <li v-for="k in panelContext.objectIdList" :class="{active:k.ObjectID===panelContext.selectObjectId}"
        @click="panelHeadContextMenuItemClick(k)" :key="k.ObjectID">
        {{k.SIM2}} - {{k.TrackerType===0?$t('onlineMonitor.haslineDevice'):$t('onlineMonitor.nolineDevice')}}
        <i v-if="k.ObjectID===panelContext.selectObjectId" class="el-icon-check" />
      </li>
    </ul>

    <detail-vehicle ref="detailVehicle"></detail-vehicle>
    <detail-device-info ref="detailDeviceInfo"></detail-device-info>
    <detail-hold ref="detailHold"></detail-hold>
    <dialog-message ref="dialogMessage"></dialog-message>
    <detail-device ref="detail" @success="handlerRefresh"></detail-device>
    <detail-device-object ref="detailObject" @success="handlerRefresh"></detail-device-object>
    <detail-device-person ref="detailPerson" @success="handlerRefresh"></detail-device-person>

  </div>
</template>

<script>
  import {
    VclCode
  } from 'vue-content-loading'
  import {
    remoteLoad,
    findFromArray,
    debounce,
    bd_encrypt
  } from '@/common/utils'
  import {
    mapGetters,
    mapMutations
  } from 'vuex'
  import TreeNav from '@/views/common/tree-nav'
  import * as API from '@/api/common'
  import BatchCommand from '@/views/batch/batch-command'
  import mixinMapCachePosition from '@/views/mixins/mixin-map-cache-position'
  // import mixinOnlineMonitor from '@/views/mixins/mixin-online-monitor'
  import {
    getDirectionDescription
  } from '@/common/dictionary'
  import SearchPlace from './components/search-place'
  import SearchEryadian from './components/search-eryadian'
  import SearchXingqudian from './components/search-xingqudian'
  import DialogCellandlbs from './components/dialog-cellandlbs'
  import mapTips from './components/mapTips'
  import deviceNum from './components/deviceNum'
  const DetailDevice = require('@/views/manage/detail/detail-device' + process.env.VUE_APP_VRC + '.vue').default
  import DetailDevicePerson from '../manage/detail/detail-device.person'
  import DetailDeviceObject from '../manage/detail/detail-device.object'

  import DetailDeviceInfo from '../manage/detail/detail-device-info'
  import DetailHold from '../manage/detail/detail-hold'
  import ArrowButton from '@/components/arrow-button'
  import {
    getMessagePhoneNumber,
    setMessagePhoneNumber,
    getLanguage,
    getGoogleKey
  } from '@/common/cache'
  import DialogMessage from '@/views/online/components/dialog-message'
  import ChangeMap from '@/views/common/change-map'
  import {
    CancelAlarm
  } from '@/common/other'
  import miment from 'miment'
  import DragWrapper from '@/components/drag-wrapper'
  import MapLayers from '@/views/mixins/map-layers'
  const mixinOnlineMonitor = require('@/views/mixins/mixin-online-monitor' + process.env.VUE_APP_VRC + '.js').default

  import {
    GetWrapper,
    GetTextView
  } from '@/views/online/google-map.js'

  const REFRESH_SPACE_TIME = 15 // 单位：秒
  export default {
    name: 'onlineMonitorGoogle',
    components: {
      ArrowButton,
      BatchCommand,
      ChangeMap,
      DragWrapper,
      VclCode,
      TreeNav,
      SearchPlace,
      SearchEryadian,
      SearchXingqudian,
      DialogCellandlbs,
      DialogMessage,
      DetailDevice,
      DetailDeviceInfo,
      DetailHold,
      DetailDevicePerson,
      DetailDeviceObject,
      mapTips,
      deviceNum
    },
    data() {
      return {
        miment,
        list: [], // 监控列表数据
        listItem: [], //加载列表数据
        // ,1: {
        //   data: device, // 设备数据
        //   marker: marker, // 车辆marker
        //   marker_text: text, // 车辆标签text
        //   infoWindow: infoWindow, // 信息窗体
        //   ObjectID //
        // }
        REFRESH_SPACE_TIME,
        mapTool: {
          ruler: null, // 比例尺
          vToolGroup: false, // 是否显示工具栏
          bilichi: false,
          gongjutiao: false,
          yingyan: false
        },
        isLoadingMapData: false,
        isFullScreen: false,
        isShoweryadian: false,
        isShowAlarmRegion: false,
        isShowPoi: false,
        isLoadingEryadian: false,
        isOnFindByRect: false,
        isOnFindByRectLoading: false,
        isShowVideo: false,
        isShowRealScene: false,
        isShowDialog: false,
        isShowDialogReal: false,
        isNavShow: true,
        isFixedToolBar: true,
        isShowPause: false,
        alarmCount: 0,
        minClusterCount: 100,
        maxClusterRegionZoom: 14,
        countdown: 15,
        currentZoom: 11,
        iframeSrc: 'http://www.365qczx.com/GIS/cmd/index.html?objectid=133018&carname=4545454&protocoltype=808&loginkey=D361F95AE1087FD091CA542FFAD4F943#/',
        table: {
          show: false,
          pageIndex: 1,
          pageSize: 10,
          isWrap: false,
          viewTable: 1,
          height: 224
        },
        isShowDialogBatch: false,
        isShowDialogBatchReal: false,
        isAnimating: false,
        isShowAddressDefault: true,
        panelContext: {
          visible: false,
          left: 0,
          top: 0,
          selectObjectId: null,
          objectIdList: []
        },
        openedSearchBox: null,
        countdown_timer: null,
        countdown_timer2: null,
        iframe: {
          title: '',
          class: '',
          width: '920px',
          height: '700px',
          src: '#'
        },
        iframeLoading: false,
        targetSIMInputVisible: false,
        targetSIM: '',

        currentCommandDefault: 10, // 批量指令默认指令类型
        checkedObjectKeysDefault: [], // 批量指令默认设备
        alarmTableLoading: false,
        alarmList: [], // 报警列表 50s刷新每次
        alarmPageIndex: 1,
        alarmPageSize: 20,
        //ifram标签url
        url: "",
        apifarm: "https://www.365qczx.com/Jiejing/StreetTC.htm?",
        // video变量
        videoUrls: "http://119.23.233.52/iGPS/dvr.aspx",
        // 参数
        parameter: {
          DeviceID: "",
          VN: "",
          ModelID: ""
        },
        upgrade: 15,
        tableSort: {
          name: '',
          orderBy: ''
        },
        currentCommandSingle: false,
        deviceInfo: {},
        platform: (process.env.VUE_APP_INFO === '.kc') && (window.location.href.search('.net') !== -1),
        dvrInfo: {
          name: '',
          paths: '',
          flag: '',
          deviceId: ''
        }
      }
    },
    watch: {
      list_length(val, oldVal) {
        if (val === 0 && oldVal > 0) {
          // 关闭计时器
          this.end_timer()
        } else if (val > 0 && oldVal === 0) {
          // 打开计时器
          this.start_timer()
        }
      },
      $route(val, oldVal) {
        // console.log(val)
        if (val.path === '/online/monitor') {
          this.refreshNow()
          if (oldVal && oldVal.path !== '/online/monitor') {
            this.$nextTick(function () {
              this.map.setCenter([this.map.getCenter().getLng() + 0.000001, this.map.getCenter().getLat() +
                0.000001])
            })
          }

          if (val.query.action === 'addTaskList') {
            if (val.query.objectid) {
              if (val.query.hasOwnProperty('clear')) {
                this.removeAllData()
              }
              this.addTaskListOne(val.query.objectid)
            } else {
              this.addTaskList()
            }

            this.$router.replace('/online/monitor')
          }
        } else {
          this.isShowVideo = false;
          this.end_timer()
        }
      },
      isShoweryadian(val) {
        if (!val) {
          this.map.clearInfoWindow()
        }
        return val ? this.addShcmToMap(this.map) : this.removeShcm()
      },
      isShowAlarmRegion(val) {
        if (!val) {
          this.infoWindow.close()
        }
        return val ? this.addRegion(this.map) : this.removeRegion()
      },
      isShowPoi(val) {
        if (!val) {
          // this.map.clearInfoWindow()
          this.infoWindow.close()
        }
        return val ? this.addPoi(this.map) : this.removePoi()
      },
      'panelContext.visible': {
        deep: true,
        handler(value) {
          if (value) {
            window.addEventListener('click', this.panelHeadCloseContextMenu)
          } else {
            window.removeEventListener('click', this.panelHeadCloseContextMenu)
          }
        }
      },
      iframeLoading(val) {
        if (val) {
          this.iframeLoading_timer = setTimeout(() => {
            this.iframeLoading = false
            this.iframeLoading_timer = null
          }, 15000)
        } else {
          if (this.iframeLoading_timer) {
            clearTimeout(this.iframeLoading_timer)
            this.iframeLoading_timer = null
          }
        }
      },
      'iframe.src': {
        deep: true,
        handler(val) {
          if (val != '#' && val != 'about:blank') {
            this.iframeLoading = true
          }
        }
      },
      'table.viewTable': {
        deep: true,
        handler(val) {
          if (val === 2 && this.alarmList.length === 0) {
            this.fetchAlarmList()
          }
        }
      }
    },
    methods: {
      // video加载
      openVideo(paths, data) {
        this.isShowVideo = true;
        this.dvrInfo.name = data.ObjectName;
        this.dvrInfo.deviceId = data.SIM2;
        this.dvrInfo.paths = paths;
        this.dvrInfo.flag = 1;
      },
      video() {
        this.videoUrls = `http://119.23.233.52/iGPS/dvr.aspx` +
          `?DeviceID=${this.parameter.DeviceID}&VN=${this.parameter.VN}&ModelID=${this.parameter.ModelID}`;
        this.isShowVideo = true;
      },
      // 关闭复位URL
      showVideo() {
        this.isShowVideo = false;
        this.videoUrls = "";
      },
      initMap(center = [116.397428, 39.90923], zoom = 11) {
        const promise = new Promise(async resolve => {
          // 加载位置
          const position = this.loadPosition('google')
          center = position.center
          zoom = position.zoom
          if (!window.google) {
            // if(d.Google_Key){ 
            //   Vue.prototype['$GOOGLE_URL_EN'] = `https://maps.googleapis.com/maps/api/js?key=${d.Google_Key}&libraries=drawing&language=en-US`
            // }
            let gmap_url = getGoogleKey() ?
              `https://maps.googleapis.com/maps/api/js?key=${getGoogleKey()}&libraries=drawing&language=en-US` :
              this.$GOOGLE_URL_EN
            await remoteLoad(gmap_url)
          }
          let maxLat = Math.atan(Math.sinh(Math.PI)) * 180 / Math.PI;
          let map = new window.google.maps.Map(this.$refs.container, {
            center: {
              lng: center[0],
              lat: center[1]
            },
            zoom: zoom,
            minZoom: 3,
            // maxZoom:18,
            mapTypeControlOptions: {
              position: window.google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_CENTER
            },
            streetViewControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_CENTER
            },
            restriction: {
              latLngBounds: {
                north: maxLat,
                south: -maxLat,
                west: -180,
                east: 180
              },
              strictBounds: true
            },
          })

          this.map = map
          this.google = window.google
          let panorama = this.map.getStreetView();
          panorama.setOptions({
            addressControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_CENTER
            },
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_CENTER
            },
          })

          this.Wrapper = GetWrapper(window.google)
          this.GView = GetTextView(window.google)

          this.ifNotExistsWindow()

          // 加载实景
          // await this.initRealScene()
          resolve()
        })
        return promise
      },
      async initRealScene() {
        if (!window.qq) {
          window.qq = {}
          window.qq.maps = {}
          window.soso = window.qq
          window.soso.maps = window.qq.maps
          window.qq.maps.__load = function (apiLoad) {
            delete window.qq.maps.__load;
            apiLoad([
              ["2.4.99", "d84d6d83e0e51e481e50454ccbe8986b", 0],
              ["https://3gimg.qq.com/", "lightmap/api_v2/2/4/99/mods/",
                "https://3gimg.qq.com/lightmap/api_v2/2/4/99/theme/", true
              ],
              [1, 18, 34.519469, 104.461761, 4],
              [1535613434068, "https://pr.map.qq.com/pingd", "https://pr.map.qq.com/pingd"],
              ["https://apis.map.qq.com/jsapi", "https://apikey.map.qq.com/mkey/index.php/mkey/check",
                "https://sv.map.qq.com/xf", "https://sv.map.qq.com/boundinfo", "https://sv.map.qq.com/rarp",
                "https://apis.map.qq.com/api/proxy/search", "https://apis.map.qq.com/api/proxy/routes/",
                "https://confinfo.map.qq.com/confinfo", "https://overseactrl.map.qq.com"
              ],
              [
                [null, ["https://rt0.map.gtimg.com/tile", "https://rt1.map.gtimg.com/tile",
                  "https://rt2.map.gtimg.com/tile", "https://rt3.map.gtimg.com/tile"
                ], "png", [256, 256], 3, 19, "114", true, false],
                [null, ["https://m0.map.gtimg.com/hwap", "https://m1.map.gtimg.com/hwap",
                  "https://m2.map.gtimg.com/hwap", "https://m3.map.gtimg.com/hwap"
                ], "png", [128, 128], 3, 18, "110", false, false],
                [null, ["https://p0.map.gtimg.com/sateTiles", "https://p1.map.gtimg.com/sateTiles",
                  "https://p2.map.gtimg.com/sateTiles", "https://p3.map.gtimg.com/sateTiles"
                ], "jpg", [256, 256], 1, 19, "101", false, false],
                [null, ["https://rt0.map.gtimg.com/tile", "https://rt1.map.gtimg.com/tile",
                  "https://rt2.map.gtimg.com/tile", "https://rt3.map.gtimg.com/tile"
                ], "png", [256, 256], 1, 19, "", false, false],
                [null, ["https://sv0.map.qq.com/hlrender/", "https://sv1.map.qq.com/hlrender/",
                  "https://sv2.map.qq.com/hlrender/", "https://sv3.map.qq.com/hlrender/"
                ], "png", [256, 256], 1, 19, "", false, false],
                [null, ["https://rtt2.map.qq.com/rtt/", "https://rtt2a.map.qq.com/rtt/",
                  "https://rtt2b.map.qq.com/rtt/", "https://rtt2c.map.qq.com/rtt/"
                ], "png", [256, 256], 1, 19, "", false, false], null, [
                  ["https://rt0.map.gtimg.com/vector/", "https://rt1.map.gtimg.com/vector/",
                    "https://rt2.map.gtimg.com/vector/", "https://rt3.map.gtimg.com/vector/"
                  ],
                  [256, 256], 3, 18, "114", ["https://rt0.map.gtimg.com/icons/",
                    "https://rt1.map.gtimg.com/icons/", "https://rt2.map.gtimg.com/icons/",
                    "https://rt3.map.gtimg.com/icons/"
                  ]
                ], null
              ],
              ["https://s.map.qq.com/TPano/v1.1.2/TPano.js", "map.qq.com/", ""]
            ], (new Date).getTime());
          }
          await remoteLoad('https://3gimg.qq.com/lightmap/api_v2/2/4/99/main.js')
        }

        let pano = new window.qq.maps.Panorama(this.$refs.pano, {
          pano: '10041022151106135344000',
          disableMove: false,
          disableFullScreen: false,
          pov: {
            heading: 20, //查看器与正北方向的水平夹角，单位为度。
            pitch: 15 //查看器与地面的垂直夹角，单位为度。
          },
          zoom: 1,
          // addressControl: true,
          // addressControlOptions: {
          //   position: window.qq.maps.ControlPosition.TOP_LEFT
          // }
        })

        this.pano = pano

        setTimeout(() => {
          this.checkFlash(this.$refs.pano)
        }, 3000)
      },
      showRealScene(lng, lat, heading, objectId) {
        if (this.infoWindow.getId() != objectId) {
          return
        }
        let data = {
          Lng: bd_encrypt(lng, lat).bd_lng,
          Lat: bd_encrypt(lng, lat).bd_lat,
          Direct: heading
        }
        // let jiejing = document.getElementById("map-iframe");
        // jiejing.contentWindow.postMessage(data,'https://www.365qczx.com/Jiejing/StreetTC.htm');
        this.isShowRealScene = true
        let jiejing = this.$refs['mainIframe']
        jiejing.contentWindow.postMessage(data, 'https://www.365qczx.com/Jiejing/StreetBD2.html');

      },
      initSimpleMarker() {
        const promise = new Promise((resolve) => {
          window.AMapUI.loadUI(['overlay/SimpleMarker'], SimpleMaker => {
            this.SimpleMaker = SimpleMaker
            resolve()
          })
        })
        return promise
      },
      ifNotExistsWindow() {
        // if (!this.infoWindow) {
        //   let infoWindow = new this.AMap.InfoWindow({
        //     isCustom: true,
        //     content: '',
        //     offset: new this.AMap.Pixel(0, -42)
        //   })
        //   infoWindow.on('close', () => {
        //   })

        //   // 根据getId获取当前监控车辆
        //   infoWindow.setId = id => infoWindow._id = id
        //   infoWindow.getId = () => infoWindow._id

        //   this.infoWindow = infoWindow
        // }
        if (!this.infoWindow) {
          let infoWindow = new this.Wrapper(
            this.map,
            new this.google.maps.LatLng(0, 0), {
              el: document.createElement('div'),
              offsetY: -35
            },
            null)

          this.infoWindow = infoWindow
        }

      },
      getSimplePanel(title, datas, padding, others = {}) {
        let panel = this.$dataPanel.create(Object.assign({
          bgColor: true,
          title: title,
          datas,
          paddingLeft: padding,
          showMore: false,
          showHeader: false,
          close: true,
          lon: others.lon,
          lat: others.lat,
          onClose: () => {
            this.infoWindow.close()
          },
          onChange: () => {}
        }, others))
        return panel
      },
      fetchByRect(minLng, minLat, maxLng, maxLat) {
        const promise = new Promise((resolve) => {
          API.FindCarByRect(minLng, minLat, maxLng, maxLat).then(ret => {
            resolve(ret.data)
          })
        })
        return promise
      },
      toggleAddress(row, bool) {
        let that = this;
        that.list.forEach((k, index) => {
          if (row.ObjectID === k.ObjectID) {
            that.list[index].data.showAddress = bool;
          }
        })
      },
      transferAddress(val) {
        let list = val
        const temp = [];
        for (let i in list) {
          let item = list[i]
          temp.push(
            new Promise((resolve, reject) => {
              let rowRefresh = true
              let currentRow = ''
              if (this.tableList.length > 0) {
                this.tableList.forEach(k => {
                  if (k.ObjectID === item.ObjectID && k.Lon === item.Lon && k.Lat === item.Lat && k.address) {
                    currentRow = k
                    rowRefresh = false
                  }
                })
              }
              if (rowRefresh) {
                API.GetAddress(item.Lon, item.Lat).then(ret => {
                  resolve(ret.data.address)
                  item.address = ret.data.address
                  list[i] = item
                })
              } else {
                resolve()
                item.address = currentRow.address
                list[i] = item
              }
            })
          )
        }
        let result = Promise.all(temp).then(res => {
          return list
        })
        return result
      },
      transferAddressOne(e) {
        let item = e
        const promise = new Promise((resolve, reject) => {
          API.GetAddress(item.Lon, item.Lat).then(ret => {
            resolve(item)
            item.address = ret.data.address
          }).catch(err => {
            reject(err)
          })
        })
        return promise
      },
      fetchAllData(list) {
        list = list || this.list.map(k => k.ObjectID)
        let items = list
        // 加载监控数据并刷新
        const promise = new Promise((resolve, reject) => {
          this.isLoadingMapData = true
          if (items.length) {
            API.QueryDeviceOnlineDataByObjectIDs(items).then(ret => {
              this.parameter.DeviceID = ret.data[0].SIM2;
              this.parameter.VN = ret.data[0].ObjectName;
              this.parameter.ModelID = ret.data[0].MDTModelID;
              if (ret.data.errCode) {
                this.$message({
                  message: ret.data.errMsg,
                  type: 'error'
                })
                return reject(ret.data)
              }
              let result = ret.data
              resolve(result)
            }).catch(err => {
              reject(err)
            }).finally(() => {
              this.isLoadingMapData = false
            })
          } else {
            let arr = []
            resolve(arr)
            this.isLoadingMapData = false
          }
        })
        return promise
      },
      fetchOneVehicleData() {
        const promise = new Promise(() => {

        })
        return promise
      },
      fetchOneDeviceData(ObjectID, VehicleID) {
        const promise = new Promise((resolve, reject) => {
          this.isLoadingMapData = true
          API.QueryDeviceOnlineData(ObjectID, VehicleID).then(ret => {
            this.isLoadingMapData = false
            if (ret.data.errCode) {
              this.$message({
                message: ret.data.errMsg,
                type: 'error'
              })
              return reject(ret.data)
            }
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        })
        return promise
      },
      fetchOneDeviceData2(ObjectID) {
        const promise = new Promise((resolve, reject) => {
          this.isLoadingMapData = true
          API.QueryDeviceOnlineDataByObjectIDs([ObjectID]).then(ret => {
            this.isLoadingMapData = false
            if (ret.data.errCode) {
              this.$message({
                message: ret.data.errMsg,
                type: 'error'
              })
              return reject(ret.data)
            }
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        })
        return promise
      },
      fetchAlarmList() {
        this.alarmTableLoading = true
        API.QueryAlarms().then(ret => {
          this.alarmTableLoading = false
          if (!ret.data.errCode) {
            this.alarmList = ret.data.list
          }
        }).catch(() => {
          this.alarmTableLoading = false
          console.error('fetchAlarmList error')
        })
      },

      diffCompare(k, device) {
        return k.ObjectID === device.ObjectID
      },
      handlerRefresh(e) {
        this.addOneDevice(e)
      },
      addOneDevice(item, autoFresh, enforce) {
        // 添加一辆车
        const promise = new Promise(async (resolve) => {
          const device = await this.fetchOneDeviceData2(item.ObjectID, item.VehicleID)
          let arr = [];
          arr.push(device[0]);
          this.fetchAddress(arr, arr).then(list => {
            this.addDataToMap(list[0], autoFresh, enforce)
          })
          resolve()
        })
        return promise
      },
      fetchRemove(device) {
        const promise = new Promise((resolve) => {
          const index = this.list.findIndex(k => this.diffCompare(k, device))
          this.list[index].marker.setMap(null)
          this.list[index].marker_text.setMap(null)
          this.list[index].overlays.forEach(j => {
            j.setMap(null)
          })
          this.listItem = this.listItem.filter(item => item !== device.ObjectID)
          this.list.splice(index, 1)
          this.infoWindow.close()
          if (this.list.length === 0 && this.listItem.length === 0) {
            this.table.show = false
          }
          resolve()
        })
        return promise
      },
      removeOneDevice(device) {
        const promise = new Promise((resolve) => {
          const index = this.list.findIndex(k => this.diffCompare(k, device))
          this.list[index].marker.setMap(null)
          this.list[index].marker_text.setMap(null)
          this.list[index].overlays.forEach(j => {
            j.setMap(null)
          })
          this.listItem = this.listItem.filter(item => item !== device.ObjectID)
          this.list.splice(index, 1)
          // this.map.clearInfoWindow()
          this.infoWindow.close()
          if (this.list.length === 0 && this.listItem.length === 0) {
            this.table.show = false
          }
          this.delTaskDevice(device.ObjectID)
          resolve()
        })
        return promise
      },
      changeSort(e) {
        this.tableSort.name = e.prop
        this.tableSort.orderBy = e.order
      },
      addDataToMap(device, isAutoRefresh, enforce) {
        // API.GetAddress(device.Lon,device.Lat).then(ret => {
        //   device.address = ret.data.address
        // })
        if (!device.RcvTime) {
          this.$message({
            message: this.$t('onlineMonitor.noPositionTip'),
            type: 'info'
          })
          return
        }

        // 根据车辆来查找
        // const index = this.list.findIndex(k => k.VehicleID === device.VehicleID)
        // return ~index ? this.addDataToMap_change(device, isAutoRefresh) : this.addDataToMap_add(device, isAutoRefresh)

        // 根据设备ID来查找
        const index = this.list.findIndex(k => this.diffCompare(k, device))
        return ~index ? this.addDataToMap_change(device, isAutoRefresh, enforce) : this.addDataToMap_add(device,
          isAutoRefresh, enforce)
      },
      addDataToMap_add(device, isAutoRefresh) {
        let marker = new this.google.maps.Marker({
          icon: {
            url: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
            anchor: new this.google.maps.Point(19, 12),
            size: new google.maps.Size(this.iconSize, this.iconSize),
            scaledSize: new google.maps.Size(this.iconSize, this.iconSize),
          },
          position: {
            lng: device.Lon,
            lat: device.Lat
          },
          title: device.ObjectName,
          map: this.map,
          extID: device.ObjectID
        })

        const panel = this.addDataToMap_getPanel(device)

        marker.addListener('click', () => {
          this.infoWindow.setContent(panel)
          this.infoWindow.setPosition(new this.google.maps.LatLng(device.Lat, device.Lon))
          this.infoWindow.setId(marker.extID)
          this.infoWindow.show()
          // this.markerClick(marker, data)
        })

        var marker_text = new this.GView({
          map: this.map,
          latlng: new this.google.maps.LatLng(device.Lat, device.Lon),
          args: {
            offsetX: 20,
            offsetY: -12,
            el: device.ObjectName
          },
          id: device.ObjectID,
          backgroundStyle: this.getTextStyle(device.VehicleNumBackColor)
        })

        if (!isAutoRefresh) {
          this.infoWindow.setContent(this.addDataToMap_getPanel(device))
          this.infoWindow.open(this.map, marker.getPosition())
          this.infoWindow.setId(device.ObjectID)
        } else if (this.infoWindow.getId() == device.ObjectID) {
          this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        }



        // 添加其他覆盖物，如LBS的圆圈
        const overlays = []
        // if (device.MainMode === 2) {
        //   // LBS
        //   let circle = new this.AMap.Circle({
        //     map: this.map,
        //     strokeColor:'#409EFF',
        //     strokeWeight: 1,
        //     fillColor:'rgba(0,0,255,1)',
        //     fillOpacity:0.1,
        //     center: [device.Lon, device.Lat],
        //     radius: device.LBS_R
        //   })
        //   overlays.push(circle)
        // }

        const item = {
          data: device,
          marker,
          marker_text,
          overlays,
          ObjectID: device.ObjectID,
          VehicleID: device.VehicleID
        }

        this.list.push(item)
        // this.map.add([marker, marker_text])

        // if (!isAutoRefresh) {
        //   this.map.setCenter([device.Lon, device.Lat])
        //   // 切换地图zoom
        //   if (this.map.getZoom() < 14) {
        //     this.map.setZoom(14)
        //   }
        // }
        // this.map.setCenter(new this.google.maps.LatLng(device.Lat, device.Lon))
        // this.map.setZoom(14)

        if (!isAutoRefresh) {
          this.map.setCenter(new this.google.maps.LatLng(device.Lat, device.Lon))
          // 切换地图zoom
          if (this.map.getZoom() < 15) {
            this.map.setZoom(18)
          }
        }

        // 修改实景位置
        this.isShowRealScene && this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
      },
      addDataToMap_change(device, isAutoRefresh, enforce) {
        this.list.forEach(k => {
          if (device.ObjectID === k.ObjectID) {
            k.data.Address = device.Address;
          }
        })
        const item = findFromArray(this.list, k => this.diffCompare(k, device))
        if (!enforce) { // 解除报警强制更新
          if (item.ObjectID === device.ObjectID && item.data.RcvTime === device.RcvTime && item.data.isOnline == device
            .isOnline && isAutoRefresh) {
            return
          }
        }

        item.data = device
        item.ObjectID = device.ObjectID
        // item.marker.setIcon(new window.AMap.Icon({
        //   size: new window.AMap.Size(this.iconSize, this.iconSize),
        //   image: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
        //   imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
        // }))

        item.marker.setIcon({
          url: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
          anchor: new this.google.maps.Point(19, 12),
          size: new google.maps.Size(this.iconSize, this.iconSize),
          scaledSize: new google.maps.Size(this.iconSize, this.iconSize),
        })
        item.marker.setPosition({
          lng: device.Lon,
          lat: device.Lat
        })
        item.marker_text.setPosition(new this.google.maps.LatLng(device.Lat, device.Lon))


        // if (!isAutoRefresh) {
        //   this.infoWindow.setPosition(new this.google.maps.LatLng(device.Lat,device.Lon))
        //   this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        //   this.infoWindow.open(this.map, [device.Lon, device.Lat])
        //   this.infoWindow.setId(device.ObjectID)
        //   this.map.setCenter(new this.google.maps.LatLng(device.Lat,device.Lon))
        //   // 切换地图zoom
        //   if (this.map.getZoom() < 14) {
        //     this.map.setZoom(14)
        //   }
        // } else if (this.infoWindow.getId() == device.ObjectID) {
        //   this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        //   this.infoWindow.setPosition(new this.google.maps.LatLng(device.Lat,device.Lon))
        // }

        if (!isAutoRefresh) {
          //let panel = this.addDataToMap_getPanel(device)
          this.infoWindow.setId(device.ObjectID)
          this.infoWindow.setContent(this.addDataToMap_getPanel(device))
          this.infoWindow.setPosition(new this.google.maps.LatLng(device.Lat, device.Lon))
          this.infoWindow.show()
        } else if (this.infoWindow.getId() == device.ObjectID) {
          this.infoWindow.setContent(this.addDataToMap_getPanel(device))
          this.infoWindow.setPosition(new this.google.maps.LatLng(device.Lat, device.Lon))
          // this.map.setCenter(new this.google.maps.LatLng(device.Lat, device.Lon))
        }

        // 修改实景位置
        // this.isShowRealScene && this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)

        if (!isAutoRefresh) {
          this.map.setCenter(new this.google.maps.LatLng(device.Lat, device.Lon))
          if (this.map.getZoom() < 24) {
            this.map.setZoom(18)
          }
        }

      },
      panelHeadContextMenuItemClick(device) {
        this.addOneDevice(device)
      },
      panelHeadCloseContextMenu() {
        this.panelContext.visible = false
      },
      mapMove() {
        if (this.isShoweryadian) {
          this.debounceAddShcm()
        }
        if (this.isShowAlarmRegion) {
          //this.debounceAddRegion()
        }
      },
      roomEnd() {
        // // 大于级别13开始显示
        // const bounds = this.map.getBounds()
        // // 西南角
        // const point_xinan = bounds.getSouthWest()
        // // 东北角
        // const point_dongbei = bounds.getNorthEast()
        // console.log(point_xinan)
        // console.log(point_dongbei)
        const zoom = this.map.getZoom()
        // console.log(zoom)
        this.currentZoom = zoom
        if (this.isShoweryadian) {
          this.debounceAddShcm()
        }
        if (this.isShowAlarmRegion) {
          //this.debounceAddRegion()
        }
      },

      addShcmToMap(map) {
        map = map || this.map
        console.log('addShcmToMap')

        const zoom = this.map.getZoom()
        if (zoom < 12) {
          // this.$message('请放大地图以显示二押点')
          this.removeShcm()
          return
        }

        // 大于级别13开始显示
        const bounds = this.map.getBounds()
        // 西南角
        const point_xinan = bounds.getSouthWest()
        // 东北角
        const point_dongbei = bounds.getNorthEast()
        console.log(point_xinan)
        console.log(point_dongbei)

        this.isLoadingEryadian = true
        API.GetShcmByLngLat(point_xinan.getLng(), point_xinan.getLat(), point_dongbei.getLng(), point_dongbei.getLat())
          .then(ret => {
            this.isLoadingEryadian = false
            const list = ret.data
            // this.$message('二押点数量' + list.length)
            console.log(list.length)
            this.removeShcm()
            list.forEach((k, index) => {
              let marker = new this.SimpleMaker({
                iconLabel: {
                  innerHTML: `<img src="${require('../../assets/icon/已抵押.svg')}" style="height:26px;width:26px;background:#FFF;border-radius:50%;transform:translateY(6px);">`
                },
                iconTheme: 'fresh',
                iconStyle: 'orange',
                map: map,
                position: [k.Lon, k.Lat],
                extData: k.RID
              })
              marker.on('click', () => {
                const d = list[index]
                this.infoWindow.setContent(this.getSimplePanel(d.PName, [{
                  title: `${this.$t('onlineMonitor.lngLat')}`,
                  text: d.Lon + ',' + d.Lat
                }], '5em', {
                  lon: d.Lon,
                  lat: d.Lat
                }))
                this.infoWindow.open(this.map, marker.getPosition())
                this.infoWindow.setId(null)
              })
              this.shcmOverlays.push(marker)

              if (list.length <= this.minClusterCount) {
                let marker_circle = new this.AMap.Circle({
                  map,
                  strokeColor: 'white',
                  fillColor: 'rgba(0,0,255,1)',
                  fillOpacity: 0.1,
                  center: [k.Lon, k.Lat],
                  radius: k.R
                })
                this.shcmOverlays.push(marker_circle)
              }

            })

            // 添加聚合
            // const opts = {
            //   url: require('../../assets/icon/darkRed.png'),
            //   size: new this.AMap.Size(48, 48),
            //   offset: new this.AMap.Pixel(-24, -24),
            //   textColor: '#FFF'
            // }
            // const sts = [opts, opts, opts, opts, opts]
            if (list.length > this.minClusterCount) {
              this.map.plugin(["AMap.MarkerClusterer"], () => {
                let cluster = new this.AMap.MarkerClusterer(map, this.shcmOverlays, {
                  // styles: sts,
                  gridSize: 80,
                  maxZoom: 17
                })

                this.shcmOverlaysJuhe.push(cluster)
                // this.cluster = cluster
                // map.setFitView()
              })
            }

          })
      },
      removeShcm() {
        this.shcmOverlays.forEach(k => k.setMap(null))
        this.shcmOverlays.splice(0)
        this.shcmOverlaysJuhe.forEach(k => k.setMap(null))
        this.shcmOverlaysJuhe.splice(0)
        // this.shcmOverlaysJuhe.forEach(k => k.setMap(null))
      },

      addRegion(map) {
        map = map || this.map
        // 大于级别13开始显示
        const bounds = this.map.getBounds()
        // 西南角
        const point_xinan = bounds.getSouthWest()
        // // 东北角
        const point_dongbei = bounds.getNorthEast()
        API.GetRegionByLngLat(point_xinan.lng(), point_xinan.lat(), point_dongbei.lng(), point_dongbei.lat()).then(
          ret => {
            const list = ret.data
            this.addRegionDataToMap(list, map)
          })
      },
      addRegionDataToMap(list, map) {
        this.removeRegion()
        list.forEach(k => {
          let riangleCoords = k.points.map(j => {
            let item = {
              lat: j.Lat,
              lng: j.Lon
            }
            return item
          })
          let polygon = new this.google.maps.Polygon({
            paths: riangleCoords,
            strokeColor: '#1890ff',
            strokeOpacity: '0.9',
            fillColor: '#1890ff',
            fillOpacity: '0.1',
            extID: k.RegionID
          })
          polygon.setMap(this.map);

          polygon.addListener('click', () => {
            this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
                title: `${this.$t('onlineMonitor.regionName')}`,
                text: k.RegionName
              },
              {
                title: `${this.$t('onlineMonitor.createTime')}`,
                text: miment(k.CreateTime).format()
              }
            ], '5em', {
              lon: riangleCoords[0].lng,
              lat: riangleCoords[0].lat
            }))
            this.infoWindow.open(this.map, new this.google.maps.LatLng(riangleCoords[0].lat, riangleCoords[0]
              .lng))
            this.infoWindow.setId(null)
          })
          this.regionOverlays.push(polygon)
          // this.setFitView(this.map,riangleCoords)

          // let polygon = new this.AMap.Polygon({
          //   map,
          //   path: k.points.map(j => [j.Lon, j.Lat]),
          //   strokeColor: '#1890ff',
          //   strokeOpacity: '0.9',
          //   fillColor: '#1890ff',
          //   fillOpacity: '0.1',
          //   extData: k.RegionID
          // })
          // polygon.getDraggable = () => false
          // polygon.getPosition = () => new this.AMap.LngLat(k.points[0].Lon, k.points[0].Lat)
          // polygon.on('click', () => {
          //   this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [
          //     { title: `${this.$t('onlineMonitor.regionName')}`, text: k.RegionName },
          //     { title: `${this.$t('onlineMonitor.createTime')}`, text: miment(k.CreateTime).format() }
          //   ], '5em', { lon: k.Lon, lat: k.Lat }))
          //   this.infoWindow.open(map, [k.points[0].Lon, k.points[0].Lat])
          //   this.infoWindow.setId(null)
          // })


          this.regionOverlays.push(polygon)
        })
        // 添加聚合
        // const opts = {
        //   url: require('../../assets/icon/darkRed.png'),
        //   size: new this.AMap.Size(48, 48),
        //   offset: new this.AMap.Pixel(-24, -24),
        //   textColor: '#FFF'
        // }
        // const sts = [opts, opts, opts, opts, opts]

        // map.plugin(["AMap.MarkerClusterer"], () => {
        //   let cluster = new this.AMap.MarkerClusterer(map, this.regionOverlays, {
        //     styles: sts,
        //     gridSize: 80,
        //     maxZoom: 14
        //   })

        //   this.regionOverlaysJuhe.push(cluster)
        //   // this.cluster = cluster
        //   map.setFitView()
        // })

      },
      removeRegion() {
        this.regionOverlays.forEach(k => {
          k.setMap(null)
          k = null
        })
        this.regionOverlays.splice(0)
        this.infoWindow.close()
        this.regionOverlaysJuhe.forEach(k => {
          k.setMap(null)
          k = null
        })
        this.regionOverlaysJuhe.splice(0)
      },

      addPoi(map) {
        map = map || this.map
        API.GetPOI('', true).then(ret => {
          const list = ret.data.splice(0, 500)
          this.removePoi()
          list.forEach((k, index) => {
            let marker = new this.google.maps.Marker({
              map: this.map,
              icon: {
                url: require('../../assets/icon/shadow_marker.png'),
                anchor: new this.google.maps.Point(19, 12),
                size: new google.maps.Size(38, 38),
                // scaledSize: new google.maps.Size(38, 38), 
              },
              // icon:  require(`../../assets/mapIcons/${k.VehicleImageUrl}`),
              position: {
                lng: k.Lon_F,
                lat: k.Lat_F
              },
              title: k.PointName,
              extID: k.PointID
            })
            marker.addListener('click', () => {
              this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
                title: `${this.$t('onlineMonitor.lngLat')}`,
                text: d.Lon_F + ',' + d.Lat_F
              }], '5em', {
                links: [this.$t('onlineMonitor.removeFavirite')],
                onLinkClick: () => {
                  this.deletePoi(d)
                },
                lon: d.Lon_F,
                lat: d.Lat_F
              }))
              this.infoWindow.open(this.map, marker.getPosition())
              this.infoWindow.setId(null)
            })
            this.poiOverlays.push(marker)
          })
          this.setFitView(this.map, this.poiOverlays)
        })
      },
      removePoi() {
        this.poiOverlays.forEach(k => {
          k.setMap(null)
          k = null
        })
        this.poiOverlays.splice(0)
        this.infoWindow.close()
      },
      deletePoi(item) {
        API.DeletePoi([item.PointID]).then(() => {
          this.$message({
            message: this.$t('onlineMonitor.deletePoiTip', {
              name: item.PointName
            }),
            type: 'success'
          })
          this.infoWindow.close()
          let target = findFromArray(this.poiOverlays, k => k.getExtData() === item.PointID)
          if (target) {
            target.setMap(null)
            target = null
          }
        })
      },
      deleteShcm(item) {
        API.DeleteShcm([item.RID]).then(() => {
          this.$message({
            message: this.$t('onlineMonitor.deletePoiTip', {
              name: item.PName
            }),
            type: 'success'
          })
          this.google ? this.infoWindow.close() : this.map.clearInfoWindow()
          let target = findFromArray(this.shcmOverlays, k => k.getExtData() === item.RID)
          if (target) {
            target.setMap(null)
            target = null
          }
        })
      },
      savePoi() {
        const handler = async e => {
          // this.map.off('click', handler)
          clickHandler.remove()
          let err = this.$t('onlineMonitor.savePoiTitle')
          let err2 = this.$t('onlineMonitor.savePoiTip2')
          const {
            value
          } = await this.$prompt(this.$t('onlineMonitor.savePoiTitle'), this.$t('onlineMonitor.savePoiTip'), {
            confirmButtonText: this.$t('common.save'),
            cancelButtonText: this.$t('common.cancel'),
            inputValidator(val) {
              if (!val) {
                return err
              } else if (val.length > 50) {
                return err2
              } else {
                return true
              }
            }
          })
          const poi = {
            HoldID: -1,
            Lon_F: e.lng(),
            Lat_F: e.lat(),
            PointName: value,
            PointType: 5,
            Remark: ''
          }
          API.AddPoi(poi).then(ret => {
            this.selectPoi(ret.data)
            this.$message({
              message: this.$t('onlineMonitor.saveSuccessed'),
              type: 'success'
            })
          })
        }

        // this.map.on('click', handler, this)
        this.$message(this.$t('onlineMonitor.savePoiTip3'))
        var clickHandler = this.map.addListener("click", (mapsMouseEvent) => {
          console.log(mapsMouseEvent.latLng)
          console.log(clickHandler)
          mapsMouseEvent.latLng && handler(mapsMouseEvent.latLng)
        })
      },
      selectRegion(item) {
        const target = findFromArray(this.regionOverlays, k => k.extID === item.RegionID)
        if (target) {
          let polygonBounds = target.getPath();
          let bounds = [];
          for (let i = 0; i < polygonBounds.length; i++) {
            let point = {
              lat: polygonBounds.getAt(i).lat(),
              lng: polygonBounds.getAt(i).lng()
            };
            bounds.push(point);
          }
          this.setFitView(this.map, bounds)
          return
        } else {
          let k = item
          let riangleCoords = k.points.map(j => {
            let item = {
              lat: j.Lat,
              lng: j.Lon
            }
            return item
          })
          let polygon = new this.google.maps.Polygon({
            paths: riangleCoords,
            strokeColor: '#1890ff',
            strokeOpacity: '0.9',
            fillColor: '#1890ff',
            fillOpacity: '0.1',
            extID: k.RegionID
          })
          polygon.setMap(this.map);

          polygon.addListener('click', () => {
            this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
                title: `${this.$t('onlineMonitor.regionName')}`,
                text: k.RegionName
              },
              {
                title: `${this.$t('onlineMonitor.createTime')}`,
                text: miment(k.CreateTime).format()
              }
            ], '5em', {
              lon: riangleCoords[0].lng,
              lat: riangleCoords[0].lat
            }))
            this.infoWindow.open(this.map, new this.google.maps.LatLng(riangleCoords[0].lat, riangleCoords[0].lng))
            this.infoWindow.setId(null)
          })
          this.regionOverlays.push(polygon)
          this.setFitView(this.map, riangleCoords)
        }
      },
      selectPoi(item) {
        let map = this.map
        const target = findFromArray(this.poiOverlays, k => k.extID === item.PointID)
        if (target) {
          this.setFitView(this.map, [target])
          return
        }
        let k = item
        let marker = new this.google.maps.Marker({
          map: map,
          icon: {
            url: require('../../assets/icon/shadow_marker.png'),
            anchor: new this.google.maps.Point(19, 12),
            size: new google.maps.Size(38, 38),
            // scaledSize: new google.maps.Size(38, 38), 
          },
          // icon:  require(`../../assets/mapIcons/${k.VehicleImageUrl}`),
          position: {
            lng: k.Lon_F,
            lat: k.Lat_F
          },
          title: k.ObjectName,
          extID: k.PointID
        })
        marker.addListener('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
            title: `${this.$t('onlineMonitor.lngLat')}`,
            text: d.Lon + ',' + d.Lat
          }], '5em', {
            links: [this.$t('onlineMonitor.removeFavirite')],
            onLinkClick: () => {
              this.deletePoi(d)
            },
            lon: d.Lon,
            lat: d.Lat
          }))
          this.infoWindow.open(this.map, marker.getPosition())
          this.infoWindow.setId(null)
        })
        this.poiOverlays.push(marker)
        this.setFitView(this.map, [marker])
      },
      // selectEryadian(item) {
      //   const target = findFromArray(this.shcmOverlays, k => k.getExtData() === item.RID)
      //   if (target) {
      //     this.map.setFitView([target])
      //     return
      //   }

      //   let k = item
      //   let marker = new this.SimpleMaker({
      //     iconLabel: {
      //       innerHTML: `<img src="${require('../../assets/icon/已抵押.svg')}" style="height:26px;width:26px;background:#FFF;border-radius:50%;transform:translateY(6px);">`
      //     },
      //     iconTheme: 'fresh',
      //     iconStyle: 'orange',
      //     map: this.map,
      //     position: [k.Lon, k.Lat],
      //     extData: k.RID
      //   })
      //   marker.on('click', () => {
      //     const d = k
      //     this.infoWindow.setContent(this.getSimplePanel(d.PName, [
      //       { title: `${this.$t('onlineMonitor.lngLat')}`, text: d.Lon + ',' + d.Lat }
      //     ], '5em', { lon: d.lon, lat: d.lat }))
      //     this.infoWindow.open(this.map, marker.getPosition())
      //   })

      //   this.shcmOverlays.push(marker)
      //   let marker_circle = new this.AMap.Circle({
      //     map: this.map,
      //     strokeColor:'white',
      //     fillColor:'rgba(0,0,255,1)',
      //     fillOpacity:0.1,
      //     center: [k.Lon, k.Lat],
      //     radius: k.R
      //   })
      //   this.shcmOverlays.push(marker_circle)
      //   this.map.setFitView([marker])
      // },

      removeDataFromMap() {

      },
      removeAllData() {
        const promise = new Promise((resolve) => {
          this.list.forEach(k => {
            k.marker.setMap(null)
            k.marker_text.setMap(null)
            k.overlays.forEach(j => {
              j.setMap(null)
            })
          })
          this.list.splice(0)
          this.alarmList.splice(0)
          this.listItem = []
          this.delTaskAllDevice()
          this.infoWindow.close()
          if (this.list.length === 0) {
            this.table.show = false
          }
          this.removePoi()
          this.removeRegion()
        })
        return promise
        //const zoom = this.map.getZoom()
        //const center = this.map.getCenter()
        //this.map.destroy()

        //this.initMap(center, zoom)
      },
      clickDevice(item, bool) {
        if (bool) {
          this.addOneDevice(item)
        } else {
          this.removeOneDevice(item)
        }
      },

      clickBatch(ObjectIDList, bool) {
        if (bool) {
          this.removeAllData()
        }
        this.isShowVideo = false;
        this.listItem = this.listItem.concat(ObjectIDList)
        let arrNew = new Set(this.listItem); //通过set集合去重
        this.listItem = Array.from(arrNew);
        let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
        let endRow = this.table.pageIndex * this.table.pageSize;
        this.fetchAllData(ObjectIDList).then(deviceInfos => {
          let pageblock = deviceInfos.slice(startRow, endRow)
          this.fetchAddress(deviceInfos, pageblock).then(list => {
            list.forEach(data => {
              this.addDataToMap(data, list.length !== 1)
            })

            // 切换地图中心
            const markers = this.list.filter(k => ObjectIDList.includes(k.ObjectID)).map(k => k.marker)
            this.setFitView(this.map, markers)

            // 点击单个设备ID时打开信息框
            if (list.length === 1) {
              // this.openInfowindowByData(list[0])
            } else {
              this.infoWindow.close()
              markers.forEach(k => {
                k.setAnimation('AMAP_ANIMATION_DROP')
              })
            }
          })
        })
      },
      fetchDevice(ObjectIDList) {
        this.fetchAllData(ObjectIDList).then(list => {
          list.forEach(data => {
            this.addDataToMap(data, list.length !== 1)
          })
          // 切换地图中心
          const markers = this.list.filter(k => ObjectIDList.includes(k.ObjectID)).map(k => k.marker)
          this.setFitView(this.map, markers)

          // 点击单个设备ID时打开信息框
          if (list.length === 1) {
            // this.openInfowindowByData(list[0])
          } else {
            this.infoWindow.close()
            markers.forEach(k => {
              k.setAnimation('AMAP_ANIMATION_DROP')
            })
          }
        })
      },
      fetchAddress(list, pages) {
        let temp = [];
        pages.forEach(k => {
          let item = {
            'Lon': k.Lon || k.data.Lon,
            'Lat': k.Lat || k.data.Lat,
            'ObjectID': k.ObjectID
          }
          temp.push(item)
        })
        const promise = new Promise((resolve) => {
          API.GetAddressList(temp).then((ret) => {
            if (ret.data.errCode) {
              return reject(ret.data)
            }
            list.forEach(k => {
              ret.data.forEach(j => {
                if (j.ObjectId === k.ObjectID) {
                  k.Address = j.Address
                  if (j.showAddress === undefined) {
                    k.showAddress = this.isShowAddressDefault
                  } else {
                    k.showAddress = j.showAddress
                  }
                }
              })
            })
            resolve(list)
          })

        })
        return promise
      },
      handleSizeChange(val) {
        this.table.pageSize = val;
        let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
        let endRow = this.table.pageIndex * this.table.pageSize;
        let pages = this.list.slice(startRow, endRow).map(k => k.ObjectID);
        this.fetchAllData(pages).then(deviceInfos => {
          // let pageblock = deviceInfos.slice(startRow,endRow)
          this.fetchAddress(deviceInfos, deviceInfos).then(list => {
            list.forEach(data => {
              this.addDataToMap(data, false)
            })

            // 切换地图中心
            const markers = this.list.filter(k => deviceInfos.includes(k.ObjectID)).map(k => k.marker)
            this.setFitView(this.map, markers)

            // 点击单个设备ID时打开信息框
            if (list.length === 1) {
              // this.openInfowindowByData(list[0])
            } else {
              this.infoWindow.close()
              markers.forEach(k => {
                k.setAnimation('AMAP_ANIMATION_DROP')
              })
            }
          })
        })
      },
      handleCurrentChange(val) {
        this.table.pageIndex = val;
        let startRow = (val - 1) * this.table.pageSize;
        let endRow = val * this.table.pageSize;
        let pages = this.list.slice(startRow, endRow).map(k => k.ObjectID);
        this.fetchAllData(pages).then(deviceInfos => {
          // let pageblock = deviceInfos.slice(startRow,endRow)
          this.fetchAddress(deviceInfos, deviceInfos).then(list => {
            list.forEach(data => {
              this.addDataToMap(data, false)
            })

            // 切换地图中心
            const markers = this.list.filter(k => deviceInfos.includes(k.ObjectID)).map(k => k.marker)
            this.setFitView(this.map, markers)

            // 点击单个设备ID时打开信息框
            if (list.length === 1) {
              // this.openInfowindowByData(list[0])
            } else {
              this.infoWindow.close()
              markers.forEach(k => {
                k.setAnimation('AMAP_ANIMATION_DROP')
              })
            }

          })

        })
      },
      setFitView(map, markers) {
        const bounds = new window.google.maps.LatLngBounds()

        if (markers instanceof Array && markers && markers.length > 0) {
          var k = markers[0]
          if (k instanceof window.google.maps.Marker) {
            const points = markers.map(k => k.getPosition())

            for (let i = 0; i < points.length; i++) {
              bounds.extend(points[i])
            }
          } else if (k instanceof window.google.maps.LatLng) {
            for (let i = 0; i < markers.length; i++) {
              bounds.extend(markers[i])
            }
          } else if (k.lng && k.lat) {
            for (let i = 0; i < markers.length; i++) {
              bounds.extend(markers[i])
            }
          }

          map.fitBounds(bounds)
        } else {
          console.error('error occured in setFitView, invlaid input')
        }
        // map.setFitView(markers)
      },
      // 根据设备信息打开对应的信息框
      openInfowindowByData(device) {
        const panel = this.addDataToMap_getPanel(device)
        this.infoWindow.setContent(panel)
        this.infoWindow.setPosition(new window.google.maps.LatLng(device.Lat, device.Lon))
        this.infoWindow.setId(device.ObjectID)
        this.infoWindow.show()
      },

      async refreshNow() {
        this.busyRefreshBtn()
        this.end_timer()
        try {
          let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
          let endRow = this.table.pageIndex * this.table.pageSize;
          this.fetchAllData().then(deviceInfos => {
            let pageblock = deviceInfos.slice(startRow, endRow)
            this.fetchAddress(deviceInfos, pageblock).then(list => {
              list.forEach(data => {
                this.addDataToMap(data, true)
              })
            })
          })
          if (this.table.show && this.table.viewTable === 2) {
            this.fetchAlarmList()
            this.$refs.deviceNum.fetchSummar()
          }
        } catch (err) {
          console.error(err)
        }
        this.start_timer()
      },

      start_timer() {
        this.countdown = REFRESH_SPACE_TIME

        const handler = async () => {
          this.upgrade = this.countdown
          if (this.countdown === 1) {
            // 刷新并等待刷新完成后重新倒计时
            clearInterval(window.countdown_timer)
            window.countdown_timer = null

            try {
              let startRow = (this.table.pageIndex - 1) * this.table.pageSize;
              let endRow = this.table.pageIndex * this.table.pageSize;
              this.fetchAllData().then(deviceInfos => {
                let pageblock = deviceInfos.slice(startRow, endRow)
                this.fetchAddress(deviceInfos, pageblock).then(list => {
                  list.forEach(data => {
                    this.addDataToMap(data, true)
                  })
                })
              })
              if (this.table.show && this.table.viewTable === 2) {
                this.fetchAlarmList()
                this.$refs.deviceNum.fetchSummar()
              }
            } catch (err) {
              console.error(err)
            }

            clearInterval(window.countdown_timer)
            window.countdown_timer = setInterval(handler, 1000)
          }

          this.countdown = this.countdown > 1 ? this.countdown - 1 : REFRESH_SPACE_TIME
        }

        clearInterval(window.countdown_timer)
        window.countdown_timer = setInterval(handler, 1000)
        // this.countdown_timer2 = setInterval(() => {
        //   this.fetchAllData().then(list => {
        //     list.forEach(data => {
        //       this.addDataToMap(data, true)
        //     })
        //   })
        // }, REFRESH_SPACE_TIME * 1000)
        this.countdown_timer = true
      },
      end_timer() {
        if (!window.countdown_timer) return
        clearInterval(window.countdown_timer)
        // clearInterval(this.countdown_timer2)
        window.countdown_timer = null
        this.countdown_timer = null
        // this.countdown_timer2 = null
      },
      toggle_ruler() {
        if (this.mapTool.ruler) {
          this.toggle_ruler__end()
        } else {
          this.toggle_ruler__start()
        }
      },
      toggle_ruler__start() {
        this.map.plugin(['AMap.RangingTool'], () => {
          this.mapTool.ruler = new this.AMap.RangingTool(this.map, {
            startLabelText: 'Start',
            endLabelText: 'end'
          })
          var str = this.mapTool.ruler.midLabelText
          this.mapTool.ruler.midLabelText = str.replace(/公里/g, 'km')
          this.mapTool.ruler.turnOn()
          this.AMap.event.addListener(this.mapTool.ruler, "end", () => {
            this.mapTool.ruler.turnOff()
            this.mapTool.ruler = null
          })
        })
      },
      toggle_ruler__end() {
        this.mapTool.ruler.turnOff()
        this.mapTool.ruler = null
      },
      rowDbClick(row) {
        // const index = this.list.findIndex(k => k.VehicleID === row.VehicleID)
        // console.log(index)
        // const panel = this.addDataToMap_getPanel(this.list[index].data)
        // this.map.setCenter(this.list[index].marker.getPosition())
        // this.infoWindow.open(this.map, this.list[index].marker.getPosition())
        // this.infoWindow.setId(this.list[index].marker.getExtData())
        // this.infoWindow.setContent(panel)
        this.addDataToMap(row, false)
      },
      rowDbClickAlarm(row) {
        this.clickBatch([row.ObjectID])
      },
      rowCancelAlarm(row) {
        CancelAlarm(this, row.ObjectID).then(() => {
          this.clickBatch([row.ObjectID], true, true)
          this.fetchAlarmList()
          this.$refs.deviceNum.fetchSummar()
        }).catch(() => {})
      },
      modifyVehicle(VehicleID) {
        this.$refs.detailVehicle.open(VehicleID, {
          tabName: 'vehicle'
        })
      },
      modifyDevice(ObjectID) {
        this.$refs.detail.open(ObjectID)
      },
      modifyUser(HoldID) {
        this.$refs.detailHold.open(HoldID)
      },
      openBatchCommand() {
        this.isShowDialogBatch = true
        this.isShowDialogBatchReal = true
        this.currentCommandSingle = true
      },
      // 发送指令
      // {
      //   ObjectID: xxxxxx,
      //   MDTTypeID: xxxxxx
      // }
      commandDevice(device) {
        //this.isShowDialog = true
        // const proto = ['vdf', 'vdf2', '808', 'hyxt', 'yiwei'][device.MDTTypeID] || 'vdf'
        // let proto = 'vdf'
        //this.iframeSrc = `http://www.365qczx.com/GIS/cmd/index.html?objectid=${device.ObjectID}&carname=${device.VehicleName}&protocoltype=${proto}&loginkey=${this.loginInfo.downMD5}#/`
        // this.openIframe({
        //   width: '920px',
        //   height: '700px',
        //   title: '',
        //   class: 'v-dialog-command',
        //   src: `http://www.365qczx.com/GIS/cmd/index.html?objectid=${device.ObjectID}&carname=${device.VehicleName}&protocoltype=${proto}&loginkey=${this.loginInfo.downMD5}#/`
        // })

        this.checkedObjectKeysDefault = [device.ObjectID]
        this.currentCommandDefault = device.MDTTypeID || 10
        this.isShowDialogBatch = true
        this.isShowDialogBatchReal = true
      },

      startFindRect() {
        this.removeAllData()
        this.isOnFindByRect = true


        if (this.mousetool) {
          this.mousetool.setMap(this.map)
          this.mousetool.setDrawingMode('rectangle')
          return
        }
        this.mousetool = new this.google.maps.drawing.DrawingManager({
          drawingControl: false,
          drawingControlOptions: {

          },
          rectangleOptions: {
            strokeColor: '#51a7f2',
            strokeOpacity: '0.9',
            fillColor: 'rgba(81,167,242,1)',
            fillOpacity: '0.1'
          },
          drawingMode: 'rectangle',
          map: this.map
        })
        this.google.maps.event.addListener(this.mousetool, 'rectanglecomplete', async rect => {
          const bounds = rect.getBounds()
          const points = [bounds.getNorthEast(), bounds.getSouthWest()]
          const minLon = Math.min(...points.map(k => k.lng()))
          const maxLon = Math.max(...points.map(k => k.lng()))
          const minLat = Math.min(...points.map(k => k.lat()))
          const maxLat = Math.max(...points.map(k => k.lat()))

          // 查找
          this.isOnFindByRectLoading = true
          const list = await this.fetchByRect(minLon, minLat, maxLon, maxLat)
          this.isOnFindByRectLoading = false
          list.forEach(k => this.addDataToMap(k))
          setTimeout(() => {
            rect.setMap(null)
            rect = null
            this.mousetool.setDrawingMode(null)
            this.mousetool.setMap(null)
          }, 2000)
        })

        // if (this.mousetool) {
        //   this.mousetool.rectangle()
        //   return
        // }
        // this.map.plugin(["AMap.MouseTool"], () => {
        //   let mousetool = new this.AMap.MouseTool(this.map)
        //   mousetool.rectangle()
        //   mousetool.on('draw', async ({obj}) => {
        //     // this.isOnFindByRect = false
        //     const points = obj.getPath()
        //     const minLon = Math.min(...points.map(k => k.lng))
        //     const maxLon = Math.max(...points.map(k => k.lng))
        //     const minLat = Math.min(...points.map(k => k.lat))
        //     const maxLat = Math.max(...points.map(k => k.lat))
        //     // 查找
        //     this.isOnFindByRectLoading = true
        //     const list = await this.fetchByRect(minLon, minLat, maxLon, maxLat)
        //     this.isOnFindByRectLoading = false
        //     obj.setMap(null)
        //     list.forEach(k => this.addDataToMap(k))
        //   })
        //   this.mousetool = mousetool
        // })

      },
      endFindRect() {
        this.isOnFindByRect = false
        this.mousetool.close(true)
      },
      ...mapMutations({
        'delTaskCar': 'DEL_TASK_CAR',
        'addTaskCar': 'ADD_TASK_CAR',
        'addTaskDevice': 'ADD_TASK_DEVICES',
        'delTaskDevice': 'DEL_TASK_DEVICES',
        'delTaskAllDevice': 'DEL_ALL_TASK_DEVICES'
      }),
      routerEnterHandler() {
        if (this.$route.query.action === 'addTaskList') {
          if (this.$route.query.objectid) {
            this.addTaskListOne(this.$route.query.objectid)
          } else {
            this.addTaskList()
          }

          this.$router.replace('/online/monitor')
        }
      },
      addTaskList() {
        console.log('addTaskList')
        this.fetchAllData(this.taskList).then(list => {
          list.forEach(data => {
            this.addDataToMap(data, true)
          })
          let markers = this.list.map(k => k.marker)
          this.setFitView(this.map, markers)
        })
      },
      addTaskListOne(objectid) {
        console.log('addTaskListOne', objectid)
        let objectIdList = objectid.indexOf(',') == -1 ? [parseInt(objectid)] : objectid.split(',').map(k => parseInt(
          k))
        this.clickBatch(objectIdList)
        // this.fetchAllData(objectIdList).then(list => {
        //   list.forEach(data => {
        //     this.addDataToMap(data)
        //   })
        // })
      },
      openIframe(options) {
        this.iframe = Object.assign(this.iframe, options)
        this.isShowDialog = true
        this.isShowDialogReal = true
      },
      closedIframe() {
        this.isShowDialogReal = false
        this.iframe.src = "about:blank";
      },
      // sendMessage(sim) {
      //   this.$prompt(this.$t('onlineMonitor.sendSMSTip'), this.$t('onlineMonitor.sendSMS'), {
      //     confirmButtonText: this.$t('common.confirm'),
      //     cancelButtonText: this.$t('common.cancel'),
      //     inputValue: sim || getMessagePhoneNumber(),
      //     inputPattern: /^\d{11,13}$/,
      //     inputErrorMessage: this.$t('onlineMonitor.sendSMSError'),
      //   }).then( ({value}) => {
      //     setMessagePhoneNumber(value)
      //     API.GetSimUrl(value).then(ret => {
      //       if (!ret.data.errCode) {
      //         console.log(ret.data)
      //         this.openIframe({
      //           width: '800px',
      //           height: '571px',
      //           class: 'el-dialog--p0',
      //           src: ret.data.url,
      //           title: `${this.$t('onlineMonitor.sendSMS')}:${value}`
      //         })
      //       } else {
      //         this.$message({
      //           message: ret.data.errMsg,
      //           type: 'error'
      //         })
      //       }
      //     })
      //   })
      // },
      sendMessage(sim) {
        console.log(sim)
        this.targetSIMInputVisible = true
        this.openIframe({
          width: '800px',
          height: '595px',
          class: 'el-dialog--p0',
          src: 'about:blank',
          title: `${this.$t('onlineMonitor.sendSMS')}`
        })
        if (sim) {
          this.targetSIM = sim
          this.sendMessageBySIM(sim)
        } else {
          sim = getMessagePhoneNumber()
          this.targetSIM = sim
          this.sendMessageBySIM(sim)
        }
      },
      sendMessageBySIM(sim) {
        setMessagePhoneNumber(sim || '')
        if (!sim) {
          this.iframe.src = "about:blank"
          return
        }
        API.GetSimUrl(sim).then(ret => {
          if (!ret.data.errCode) {
            console.log(ret.data)
            this.targetSIMInputVisible = true
            this.openIframe({
              width: '800px',
              height: '595px',
              class: 'el-dialog--p0',
              src: ret.data.url,
              title: `${this.$t('onlineMonitor.sendSMS')}`
            })
          } else {
            this.$message({
              message: ret.data.errMsg,
              type: 'error'
            })
          }
        })
      },
      sendDeviceMessage() {
        this.$refs.dialogMessage.open()
      },
      refreshAlarmList() {
        if (this.timer_activeAlarmList) {
          return
        }

        this.timer_activeAlarmList = setInterval(() => {
          this.fetchAlarmList()
          this.$refs.deviceNum.fetchSummar()
        }, 50000)

        this.$once('hook:beforeDestroy', () => {
          console.log('clear timer_activeAlarmList')
          clearInterval(this.timer_activeAlarmList)
        })
      },
      // 打开并加载报警列表、每50s刷新一次
      activeAlarmList() {
        if (this.table.show && this.table.viewTable === 2) {
          this.$refs.deviceNum.fetchSummar()
          this.fetchAlarmList()
        } else {
          // 打开报警列表
          if (!this.timer_activeAlarmList) {
            this.refreshAlarmList()
          }
          this.table.show = true
          this.table.viewTable = 2
        }
      },
      getDirectionDescription
    },
    computed: {
      bottomHeight() {
        if (this.table.show === false && this.isNavShow === true) {
          return `bottom:10px;left:14.5%`;
        } else if (this.table.show === true && this.isNavShow === true) {
          return `bottom:${this.table.height+24}px;left:14.5%`;
        } else if (this.table.show === false && this.isNavShow === false) {
          return `bottom:10px;left:10px`
        } else if (this.table.show === true && this.isNavShow === false) {
          return `bottom:${this.table.height+24}px;left:14px`
        }
      },
      list_length() {
        return this.list.length || 0
      },
      isVisibleEryadianTip() {
        return this.isShoweryadian && this.currentZoom < 12
      },
      tableList() {
        let sheetList = this.list.map(k => k.data).slice((this.table.pageIndex - 1) * this.table.pageSize, this.table
          .pageIndex * this.table.pageSize)
        return sheetList
      },
      pageAlarmList() {
        return this.alarmList.map(k => k).splice((this.alarmPageIndex - 1) * this.alarmPageSize, this.alarmPageSize)
      },
      ...mapGetters([
        'loginInfo',
        'taskList',
        'currentHoldId'
      ])
    },
    created() {
      this.shcmOverlays = []
      this.shcmOverlaysJuhe = []
      this.regionOverlays = []
      this.regionOverlaysJuhe = []
      this.poiOverlays = []
      this.currentMapLayer = 4

      // this.tConfig.poi = false
      // this.tConfig.region = false
      // this.tConfig.rect = false
    },
    mounted() {
      this.initMap().then(this.routerEnterHandler)

      this.debounceAddShcm = debounce(this.addShcmToMap, 500)
      this.debounceAddRegion = debounce(this.addRegion, 500)
      // this.start_timer()
    },
    mixins: [
      mixinMapCachePosition,
      mixinOnlineMonitor,
      MapLayers
    ]
  }

</script>

<style lang="scss" scoped>
  @import "../../styles/variables-simple.scss";

  // // 左导航栏z-index
  // $page-nav-zindex: 112;
  // // 左导航栏z-index
  // $page-nav-tool-zindex: 112;
  .tool-btns {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // z-index: 200;
    z-index: $page-nav-tool-zindex;
    height: 40px;
    background-color: rgba(255, 255, 255, .7);
    float: right;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $page-main-tool-height;
    border-bottom: 1px solid rgba($--color-primary, .12);

    &.is-FixedToolbar {
      background-color: rgba(255, 255, 255, 1);
    }

    &>.el-button:not(.first) {
      margin-left: 10px;
      font-size: 13px;
    }

    &.isNavShow {
      left: 250px;
      width: calc(100% - 250px);
    }

    // &.isShowRealScene {
    //   //transform: translateX(-40px);
    // }
    .loading-container {
      position: absolute;
      z-index: 1;
      height: 40px;
      width: 40px;
      left: 0;
      top: 0;

      &__inner {
        height: 40px;
        width: 40px;

        & ::v-deep .el-loading-mask {
          background-color: transparent;

          .el-loading-spinner {
            margin-top: -12px;

            svg {
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }
  }

  .tool-btn {
    font-size: 14px;
    padding: 4px;
  }

  .i-main-content-wrapper {
    position: relative;
    display: flex;
    align-items: stretch;

    .tree-nav {
      background-color: #FFF;
      // width: 540px;
      // flex-shrink: 0;
      // overflow-y: auto;
      position: relative;
      // z-index: 112;
      z-index: $page-nav-zindex;
    }

    .subBlock {
      display: flex;
      flex-grow: 1;
      position: relative;
      z-index: 1;
    }

    .map {
      flex-grow: 1;
      overflow: hidden;

      &.is-animate {
        overflow: hidden;
      }

      &.is-FixedToolbar {
        margin-top: 40px;
      }

      & ::v-deep .amap-logo,
      & ::v-deep .amap-copyright {
        display: none !important;
        z-index: -1;
      }

      & ::v-deep .amap-marker-label {
        border: 1px solid #CCC;
        border-radius: 2px;
        background-color: rgba(#FFF, .85);
        font-weight: 600;
      }
    }

    .toggleNav {
      position: absolute;
      left: 250px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 102;
      font-size: 56px;
      cursor: default;

      &.isHide {
        left: 0;
      }
    }

    .v-detail {
      position: absolute;
      z-index: 111;
      left: 0px;
      bottom: 0;
      //height: 250px;
      border-top: 1px solid #DCDFE6;
      width: calc(100% - 0px);
      box-sizing: content-box;
      background-color: #FFF;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);

      &.isHide {
        left: 0;
        width: calc(100%);
      }

      .v-pager {
        $color: rgba(0, 0, 0, .95);
        text-align: right;
        background-color: #F4F4F8;
        padding: 0;

        & ::v-deep span:not([class*=suffix]),
        & ::v-deep button,
        & ::v-deep .el-pager,
        & ::v-deep .el-pager li,
        & ::v-deep .el-input,
        & ::v-deep .el-input input {
          height: 20px;
          line-height: 20px;
        }

        & ::v-deep .el-pagination__total {
          float: left;
          color: $color;
          font-size: 12px;
          padding-left: 8px;
        }

        & ::v-deep .el-pagination__sizes {
          float: left;
          color: $color;
        }

        & ::v-deep .el-input__inner {
          border-radius: 2px !important;
          color: $color;
        }

        & ::v-deep .el-pagination__jump {
          color: $color;
        }
      }

      .table-check {
        position: absolute;
        z-index: 111;
        right: 10px;
        top: 8px;
        background-color: #F4F4F8;

        .el-button {
          padding: 0 4px;

          & ::v-deep span {
            font-size: 12px;
          }

          & ::v-deep i {
            font-size: 13px;
          }
        }

        .table-exchange {
          height: 20px;
          width: 20px;
          margin-left: 8px;
          vertical-align: middle;
          cursor: pointer;
        }

        .el-checkbox {
          vertical-align: middle;

          & ::v-deep .el-checkbox__label {
            font-size: 12px;
          }
        }
      }

      .toggleTable {
        position: absolute;
        left: 50%;
        top: -16px;
        transition: all 0.3s ease;
        transform: translateX(-50%) translateY(0);
        z-index: 102;
        font-size: 56px;
        cursor: default;
      }
    }

    .v-video.is-video {
      position: absolute;
      // top: 64%;
      left: 14%;
      z-index: 888;

      .video {
        width: 500px;
        height: 300px;
      }

      &:hover {
        z-index: 1000;
      }

      .closeVideo {
        position: absolute;
        top: 15px;
        left: 445px;
        z-index: 5;
        font-size: 16px;
        padding: 8px 9px;
        border-radius: 2px;
        opacity: .7;
        background-color: $--color-danger;
        color: #FFF;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }

    .v-pano.is-panoopen {
      flex-grow: 1;
      position: relative;
      width: 0%;
      margin-top: 40px;
      z-index: 109;
      overflow: hidden;

      &:hover {
        z-index: 109;
      }

      .closeBtn {
        position: absolute;
        top: 20px;
        left: 100px;
        z-index: 5;
        font-size: 16px;
        padding: 8px 9px;
        border-radius: 2px;
        opacity: .7;
        background-color: $--color-danger;
        color: #FFF;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }

    .v-pano {
      .v-wrap {
        height: 105%;

        .box {
          width: 100%;
          height: 100%;
        }
      }

      .nofollow {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }



    .map--tip {
      position: absolute;
      z-index: 151;
      left: 0;
      top: 48px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;

      &.isNavShow {
        left: 50px;
      }

      $bk: rgba(255, 255, 255, 0.7);
      $color: #607D8B;
      $border: #CFD8DC;
      $bk-highlight: #BBDEFB;
      $bk-highlight-color: $--color-primary;

      .countdown {
        min-width: 120px;
        padding: 0 12px;
        background-color: $bk;
        color: $color;
        user-select: none;

        .countdown__number {
          color: $--color-primary;
          margin-right: 2px;
        }
      }

      .tip__item {
        background-color: $bk;
        color: $bk-highlight-color;
        padding: 0 12px;
        white-space: nowrap;
        width: fit-content;
        border-radius: 4px;

        .item__action {
          color: $color;
          margin-left: 2px;
          cursor: pointer;
        }

        .item__split {
          height: 1px;
          display: block;
          background-color: $border;
        }

        & ::v-deep span {
          color: $color;
        }
      }

      p:not(:first-child) {
        margin-top: 5px;
      }
    }

    .fixed-tool {
      position: absolute;
      right: 0;
      top: 100px;
      z-index: 151;
      font-size: 14px;

      >ul {
        list-style: none;
        display: inline-block;
        vertical-align: top;

        li {
          height: 40px;
          width: 40px;
          background-color: rgba(0, 0, 0, 0.6);
          color: #FFF;
          border-radius: 4px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;

          &:hover {
            background-color: #000;
          }

          &.active {
            background-color: #9ed485;
          }

          .icon {
            font-size: 20px;
          }
        }
      }

      .layers-ui {
        width: 200px;
        display: inline-block;
        vertical-align: top;
        background-color: #FFF;
        overflow-y: auto;

        .sidebar_heading {
          padding: 10px 20px;

          .el-icon-close {
            float: right;
            cursor: pointer;
          }
        }

        .base-layers {
          padding: 10px 20px;
          border-bottom: 1px solid #DDD;

          ul {
            li {
              overflow: hidden;
              border-radius: 3px;
              border: 2px solid #FFF;
              margin-bottom: 8px;
              position: relative;
              height: 50px;
              display: inline-block;
              cursor: pointer;
              transition: border-color 0.08s ease-in;

              &:hover {
                border-color: #CCC;
              }

              &.active {
                border-color: #60a94f;
              }

              &.hide {
                display: none;
              }

              label {
                position: absolute;
                top: 0;
                z-index: 1;
                left: 0;
                padding: 2px 6px;
                font-size: 16px;
                font-weight: 600;
                background-color: rgba(255, 255, 255, 0.9);
                border-bottom-right-radius: 3px;
              }
            }
          }
        }
      }
    }

    & ::v-deep .amap-logo {
      bottom: 2px !important;
    }

    & ::v-deep .amap-overlay-text-container {
      font-size: 12px;
      background-color: #FFF;
      border-color: #1E88E5;
      padding: 4px 4px;
      opacity: 0.8;
      //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
    }

    & ::v-deep .amap-scalecontrol {
      z-index: 99;
    }
  }

  .badge-item {
    height: 28px;
    max-width: 90px;
    display: flex;
    align-items: center;

    .icon {
      font-size: 14px;
      margin-right: 2px;
      margin-left: -2px;
      color: #f44336;
      animation: flash infinite 1s linear;
    }

    & .el-button--small.is-round {
      padding: 6px 16px;
    }
  }

  @keyframes flash {
    10% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    90% {
      opacity: 1;
    }
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 201;
    position: fixed;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: $--color-text-regular;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;

      &:hover {
        background: #eee;
      }

      &.active {
        color: $--color-primary;
      }
    }
  }


  .targetSIMInput {
    padding: 0 20px;
    height: 48px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .1);
    position: relative;
    z-index: 3;

    span {
      color: var(--color-p);
      white-space: nowrap;
      flex-shrink: 0;
      font-size: 16px;
    }

    .el-input {
      flex-grow: 1;

      & ::v-deep .el-input__inner {
        border-color: transparent;
        background-color: #F4F4F8;
      }
    }
  }

  .targetSIMInput-empty {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      color: rgba(0, 0, 0, .26);
      font-size: 128px;
      margin-top: -24px;
    }

    span {
      color: rgba(0, 0, 0, .26);
      margin-top: 24px;
      font-size: 16px;
    }
  }

  .square {
    height: 14px;
    width: 14px;
    display: block;
    border-radius: 2px;
    background-color: #BDBDBD;
    margin-left: 4px;

    &.isOnline {
      background-color: #4CAF50;
    }
  }

  .imgcolumn {
    img {
      margin-left: 4px;
      vertical-align: text-top;
      margin-top: -1px;
    }
  }

  .kycsTip {
    margin: 0 auto;
    color: #ed242b;
    font-size: 18px;
    font-weight: 600;

    a {
      color: #11aef4;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .tipGroup {
    width: 100%;
    position: absolute;
    top: 0px;
  }

  .tracktable {
    & ::v-deep .caret-wrapper {
      height: 22px;

      .ascending {
        top: 0;
      }

      .descending {
        bottom: 0;
      }
    }
  }

</style>
